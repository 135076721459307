import i18n from '@/i18n'
import Store from '@/store'
import ReportHeading from '@/utils/report-head'
import pdfMake from 'pdfmake/build/pdfmake'
import pdfFontsBn from 'pdfmake/build/vfs_fonts_bn'
import pdfFontsEn from 'pdfmake/build/vfs_fonts_en'
import { mobileNumber } from '@/utils/fliter'
const exportPdfDetails = async (baseUrl, uri, orgId, reportTitle, data, vm, trainees) => {
    try {
      Store.commit('mutateCommonProperties', {
        loading: true
      })
      if (i18n.locale === 'bn') {
        pdfMake.vfs = pdfFontsBn.pdfMake.vfs
        pdfMake.fonts = {
          Kalpurush: {
            normal: 'Kalpurush.ttf',
            bold: 'Kalpurush.ttf',
            italics: 'Kalpurush.ttf',
            bolditalics: 'Kalpurush.ttf'
          }
        }
      } else {
          pdfMake.vfs = pdfFontsEn.pdfMake.vfs
          pdfMake.fonts = {
            Roboto: {
              normal: 'Roboto-Regular.ttf',
              bold: 'Roboto-Medium.ttf',
              italics: 'Roboto-Italic.ttf',
              bolditalics: 'Roboto-MediumItalic.ttf'
            }
          }
      }
        const reportHeadData = await ReportHeading.getReportHead(baseUrl, uri, orgId)
        const pdfContent = [{
            columns: reportHeadData.reportHeadColumn
          },
          { text: reportHeadData.orgName, style: 'org', alignment: 'center' },
          { text: reportHeadData.projectName, style: 'address', alignment: 'center' },
          { text: reportHeadData.address, style: 'address', alignment: 'center' },
          { text: reportTitle, style: 'hh', alignment: 'center' }
        ]
        const bodySeperate = [
          [
            { text: vm.$t('elearning_iabm.circular_memo_no'), style: 'th', alignment: 'left' },
            { text: ':', style: 'td', alignment: 'center' },
            { text: data.circular_memo_no, style: 'td', alignment: 'left' },
            { text: vm.$t('elearning_config.fiscal_year'), style: 'th', alignment: 'left' },
            { text: ':', style: 'td', alignment: 'center' },
            { text: vm.$i18n.locale === 'bn' ? data.fiscal_year_bn : data.fiscal_year, style: 'td', alignment: 'left' }
          ]
        ]
        bodySeperate.push([
          { text: vm.$t('globalTrans.organization'), style: 'th', alignment: 'left' },
          { text: ':', style: 'td', alignment: 'center' },
          { text: vm.$i18n.locale === 'bn' ? data.org_bn : data.org, style: 'td', alignment: 'left' },
          { text: vm.$t('elearning_config.training_type'), style: 'th', alignment: 'left' },
          { text: ':', style: 'td', alignment: 'center' },
          { text: vm.$i18n.locale === 'bn' ? data.training_type_bn : data.training_type, style: 'td', alignment: 'left' }
        ])
        bodySeperate.push(
          [
            { text: vm.$t('elearning_config.training_title'), style: 'th', alignment: 'left' },
            { text: ':', style: 'td', alignment: 'center' },
            { text: vm.$i18n.locale === 'bn' ? data.training_title_bn : data.training_title, style: 'td', alignment: 'left' },
            { text: vm.$t('elearning_config.batch'), style: 'th', alignment: 'left' },
            { text: ':', style: 'td', alignment: 'center' },
            { text: vm.$i18n.locale === 'bn' ? data.batch_bn : data.batch_en, style: 'td', alignment: 'left' }
          ]
          )
          pdfContent.push({
            table: {
              style: 'tableData',
              headerRows: 0,
              widths: ['16%', '1%', '33%', '16%', '1%', '33%'],
              body: bodySeperate
            },
            layout: {
              hLineWidth: function (i, node) {
                  return 0
              },
              vLineWidth: function (i, node) {
                  return 0
              }
            }
        })
      pdfContent.push({ text: vm.$t('elearning_tim.released_order') + ' ' + vm.$t('globalTrans.list'), style: 'hh', alignment: 'center' })
      const allRows = [
        [
          { text: vm.$t('globalTrans.sl_no'), style: 'th', alignment: 'center' },
          { text: vm.$t('globalTrans.name'), style: 'th', alignment: 'center' },
          { text: vm.$t('globalTrans.designation'), style: 'th', alignment: 'center' },
          { text: vm.$t('globalTrans.mobile'), style: 'th', alignment: 'center' }
        ]
      ]
      trainees.filter((data, optionIndex) => {
        allRows.push(
          [
            { text: vm.$n(optionIndex + 1), style: 'td', alignment: 'center' },
            { text: (vm.$i18n.locale === 'bn' ? data.name_bn : data.name), style: 'td', alignment: 'center' },
            { text: (vm.$i18n.locale === 'bn' ? data.designation_bn : data.designation_en), style: 'td', alignment: 'center' },
            { text: mobileNumber(data.mobile), style: 'td', alignment: 'center' }
          ]
        )
      })
      pdfContent.push({ text: '', style: 'tableMargin' })
      pdfContent.push({
        table: {
          headerRows: 1,
          widths: ['10%', '30%', '30%', '30%'],
          body: allRows
        }
      })
      var docDefinition = {
      content: pdfContent,
      pageSize: 'A4',
      pageOrientation: 'portrait',
      styles: {
        th: {
          fontSize: (i18n === 'bn') ? 10 : 8,
          margin: [3, 3, 3, 3]
        },
        td: {
          fontSize: (i18n === 'bn') ? 10 : 8,
          margin: [3, 3, 3, 3]
        },
        header: {
          fontSize: 14,
          bold: true,
          alignment: 'center',
          margin: [0, 0, 0, 0]
      },
      org: {
          fontSize: 13,
          bold: true,
          alignment: 'center',
           margin: [0, -25, 0, 0]
      },
      address: {
          fontSize: 11,
          margin: [0, 0, 0, 5]
      },
      hh: {
          fontSize: 13,
          bold: true,
          margin: [10, 10, 25, 20]
      },
        header2: {
          fontSize: 14,
          margin: [0, 10, 0, 20]
        },
        fertilizer: {
          fontSize: 10,
          margin: [10, 10, 0, 20]
        },
        headerPort1: {
          fontSize: 10,
          margin: [0, 20, 0, 0]
        },
        headerPort: {
          fontSize: 10,
          margin: [0, 4, 0, 15]
        },
        krishi: {
          margin: [0, -5, 0, 15],
          alignment: 'center'
        },
        header3: {
          fontSize: 9,
          margin: [0, 0, 0, 4]
        },
        tableSubHead: {
          margin: [0, 5, 0, 15]
        }
      }
    }
    pdfMake.createPdf(docDefinition, null, null, null).print()
  } catch (error) {
    if (error) {}
  }
  Store.commit('mutateCommonProperties', {
    loading: false
  })
}
export default {
  exportPdfDetails
}
